@import '../../styles/functions';
@import '../../styles/variables';

.container {
  background-color: $color-white;
}

.headerText {
  color: $color-grey;
  font-family: $font-family-primary;
  font-weight: 500;
  font-size: 60px;
  line-height: 45px;
  text-align: center;
}

.suggestionHeaderText {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: 23px;
  line-height: 23px;
  text-align: center;
}

.textStyle {
  color: $color-grey;
  font-family: $font-family-secondary;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: .43px;
  line-height: 26px;
  text-align: center;
  font-weight: 700;
}
