@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  background-color: $color-white;
}

.chartContainer {
  background-color: $color-grey-medium;
  color: $color-black;
  box-shadow: inset 0 1px 20px 0 rgba($color-black, 0.06);
}

.infoIcon {
  font-size: 15px;
}

.headerText {
  position: relative;
  color: $color-grey;
  font-family: $font-family-primary;
  font-weight: 500;
  font-size: 60px;
  text-align: center;
  line-height: 45px;
}

.textStyle {
  color: $color-grey;
  font-family: $font-family-secondary;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-align: center;
  font-weight: 700;
}

.textStyleBold {
  font-family: $font-family-secondary-bold;
}

.percentageResult {
  font-family: $font-family-primary;
  font-size: 100px;
  align-self: center;
  color: $color-pink;
  margin-top: 55px;
}
.percentageResultUnit {
  font-size: 80px;
  color: $color-grey;
  align-self: center;
}

@include media-breakpoint-down(sm) {
  .headerText {
    font-size: 28px;
  }

  .infoIcon {
    margin-top: -4px;

    svg {
      font-size: 12px;
    }
  }
}
