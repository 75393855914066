@import './functions';

//
// Color system
//

$white:    #fff !default;
$gray-100: #f3f7f7 !default;
$gray-200: #e8eff0 !default;
$gray-300: #dee2e6 !default;
$gray-400: #EBEBEB !default;
$gray-500: #D8D8D8 !default;
$gray-600: #B2C3CE !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900
  ),
  $grays
);

$color-blue:        #191F24;
$color-indigo:      #6610f2 !default;
$color-purple:      #6f42c1 !default;
$color-violet:      #E3E0F2;
$color-pink:        #e83e8c !default;
$color-red:         #cc0000;
$color-orange:      #f17500 !default;
$color-yellow:      #F5DE00 !default;
$color-green:       #6ca234 !default;
$color-green-light: #40C1AC;
$color-green-medium:  #115E67;
$color-teal:        #20c997 !default;
$color-cyan:        #17a2b8 !default;

$color-black:      #191F24;
$color-white:      #ffffff;
$color-white-08:   rgba(255,255,255,0.8);
$color-border:      $color-green;
$color-dark-white: #F5F5F6;
$color-orange:     #DD6327;
$color-grey:       #58585B;
$color-grey-light: #979797;
$color-grey-medium:#F2F5F7;
$color-grey-dark:  #979797;
$color-pink: 	     #EA526F;
$color-blue-light: #0CC0D8;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'black':        $color-black,
    'white':        $color-white,
    'blue':         $color-blue,
    'indigo':       $color-indigo,
    'purple':       $color-purple,
    'pink':         $color-pink,
    'red':          $color-red,
    'orange':       $color-orange,
    'yellow':       $color-yellow,
    'green':        $color-green,
    'green-light':  $color-green-light,
    'teal':         $color-teal,
    'cyan':         $color-cyan,
    'gray':         $gray-600,
    'gray-dark':    $gray-800,
    'gray-light':   $color-grey-light,
    'violet':       $color-violet
  ),
  $colors
);

$primary:       #2D3145;
$secondary:     $gray-600 !default;
$success:       $color-green !default;
$info:          $color-cyan !default;
$warning:       $color-yellow !default;
$danger:        $color-red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary':    $primary,
    'secondary':  $secondary,
    'success':    $success,
    'info':       $info,
    'warning':    $warning,
    'danger':     $danger,
    'light':      $light,
    'dark':       $dark
  ),
  $theme-colors
);

$body-bg:                   $color-white;
$body-color:                $gray-900 !default;

// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;

// The yiq lightness value that determines when the lightness of color changes from 'dark' to 'light'. Acceptable values are between 0 and 255.
$yiq-contrasted-threshold:  150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark:             $gray-900 !default;
$yiq-text-light:            $white !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1393px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$sizes: (
  25: 25%,
  30: 30%,
  50: 50%,
  60: 60%,
  75: 75%,
  100: 100%,
  auto: auto
);
// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           40px;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 5px;

// stylelint-disable-next-line scss/dollar-variable-default
$spacers: (
  0: 0,
  1: $spacer,
  2: ($spacer * 2),
  3: ($spacer * 4),
  4: ($spacer * 8),
  5: ($spacer * 16),
  6: ($spacer * 28)
);

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      HelveticaNowDisplay, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !default;
$font-family-primary:         'BalooRegular', sans-serif;
$font-family-secondary:       'ColfaxRegular', sans-serif;
$font-family-secondary-semi-bold:  'ColfaxMedium', sans-serif;
$font-family-secondary-bold:  'ColfaxBold', sans-serif;
$font-family-base:            $font-family-secondary !default;

$font-size-base:              px-to-base-rem(19);
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;

$font-weight-lighter:         100;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.2631;

$h1-font-size:                px-to-base-rem(60);
$h2-font-size:                px-to-base-rem(35);
$h3-font-size:                px-to-base-rem(30);
$h4-font-size:                px-to-base-rem(28);
$h5-font-size:                px-to-base-rem(28);
$h6-font-size:                $font-size-base !default;

$headings-margin-bottom:      1rem;
$headings-font-family:        inherit !default;
$headings-font-weight:        700;
$headings-line-height:        1.1 !default;
$headings-color:              inherit !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              ($font-size-base * 1.25) !default;
$lead-font-weight:            300 !default;

$small-font-size:             81.9% !default;

$text-muted:                  $gray-600;

$blockquote-small-color:      $gray-600;
$blockquote-font-size:        ($font-size-base * 1.25) !default;

$hr-border-color:             rgba($black, .1) !default;

$link-color:                  inherit;
$link-decoration:             none;
$link-hover-color:            inherit;
$link-hover-decoration:       none;

$figure-caption-font-size:    $font-size-base * .636364;
$figure-caption-color:        $black;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$border-width:                1px !default;
$border-color:                $gray-400;

$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;

$transition-base:             all .2s ease-in-out !default;
$transition-fade:             opacity .15s linear !default;
$transition-collapse:         height .35s ease !default;
$transition-duration:         .3s;
$transition-duration-slow:    .6s;
$transition-bezier:           .3s cubic-bezier(.41, .28, .66, .97);

$sidebar-width: 120px;
$main-nav-height: 120px;
