@mixin custom-font($font, $family: arial, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$font}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin custom-font-otf($font, $family: arial, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$font}.otf') format('opentype');
    font-weight: $weight;
    font-style: $style;
  }
}
