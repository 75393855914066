@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.carContainer {
  overflow: hidden;
}

.wrapper {
  display: flex;
  justify-content: center;

  opacity: 0;
  animation: rightIn 1.3s ease-out;
  animation-fill-mode: both;
  transform-origin: bottom left;
  will-change: opacity, transform;

  @for $i from 1 through 15 {
    &:nth-child(#{$i}) {
      animation-delay: #{$i * 0.05}s;
    }
  }

  @keyframes rightIn {
    0% {
      opacity: 1;
      transform: translateX(100vw);
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.plusIcon {
  svg {
    font-size: 62px;
    color: $color-grey;
  }
}

.wrapperHalf {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    left: 75px;
    top: 0;
    bottom: 0;
    background-color: $color-white;
  }
}

.carImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 50px;
  width: 100px;
}

@include media-breakpoint-down(sm) {
  .carImg {
    width: 80px;
  }

  .plusIcon {
    svg {
      font-size: 50px;
    }
  }
}
