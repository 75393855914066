@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  background-color: $color-white;
  color: $color-black;
  cursor: pointer;

  p {
    font-family: $font-family-secondary-bold;
    color: $color-grey;
  }
}

.headerText {
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}

.textStyle {
  color: $color-grey;
  font-family: $font-family-secondary;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: .43px;
  line-height: 26px;
  text-align: center;
}

.subHeading {
  font-family: $font-family-secondary;
  color: $color-grey;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-align: center;
  font-weight: $font-weight-lighter;
}

.description {
  margin-top: -10px;
}

.radioButton {
  box-sizing: border-box;
  height: 28px;
  width: 28px;
  border: 1px solid $color-grey-light;
  border-radius: 14px;
}

.radioButtonSelected {
  box-sizing: border-box;
  height: 28px;
  width: 28px;
  border: 3px solid $color-green;
  border-radius: 14px;
}

.pickerTitle {
  text-align: center;
}

@include media-breakpoint-up(lg) {
  .radioButton,
  .radioButtonSelected {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
