@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  background-color: $color-white;
  color: $color-grey;
  height: 100%;
}

.linkText {
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  font-weight: 500;

  strong {
    font-family: $font-family-secondary-bold;
  }

  ol,
  ul {
    line-height: 26px;
  }

  ol {
    list-style: none;
    counter-reset: item;

    li {
      counter-increment: item;
    }

    li::before {
      content: counter(item) '.';
      font-size: 20px;
      font-family: $font-family-secondary-bold;
      line-height: 24px;
      margin-left: -2rem;
      text-align: left;
      margin-right: 1rem;
    }
  }
}

.info {
  text-align: center;
}

.headerText {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: 23px;
  line-height: 23px;
  text-align: center;
}

.arrowButtons {
  overflow: visible;
  position: absolute;
  top: 0;
  height: 200px;
  background: transparent;
  border: none;
  font-size: 60px;
  font-family: $font-family-secondary-bold;
  color: $gray-500;
  z-index: 1;

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    top: 0;
  }
}

@include media-breakpoint-down (md) {
  .arrowButtons {
    top: 80px;
  }

  .linkText {
    p {
      font-size: 14px;
      line-height: 17px;
    }
  }

  &:last-of-type {
    svg {
      margin-left: -2px;
    }
  }
}
