.table {
  width: 100%;
  font-size: px-to-base-rem(15);
  font-weight: 300;
  border: 0;

  thead,
  tbody {
    th {
      font-family: $font-family-secondary;
      font-size: px-to-base-rem(18);
      font-weight: 300;
      padding: 16px 10px 16px 0;
      border-bottom: 2px solid $color-border;
    }
  }

  tbody {
    td {
      padding: 20px;
      border-right: 2px solid $color-white;

      &:last-of-type {
        border-right: 0;
      }
    }

    tr {
      border-bottom: 2px solid $color-white;

      &:last-of-type {
        border-bottom: 0;
      }

      &:nth-child(odd) {
        background-color: $gray-200;
      }

      &:nth-child(even) {
        background-color: $gray-100;
      }
    }
  }

  &--container {
    @media (max-width: map-get($grid-breakpoints, lg)) {
      overflow: auto;
      padding-bottom: 20px;
      padding-top: 12px;
      margin-top: -12px;
    }
  }

  @media (max-width: map-get($grid-breakpoints, sm)) {
    font-size: 16px;
    overflow: auto;
    display: block;
    max-width: 100%;

    thead th {
      padding: 18px 20px;
    }

    tbody td {
      padding: 12px 20px;
    }
  }
}

table {
  @extend .table;
}
