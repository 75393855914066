@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  color: $color-black;
  position: relative;
}

.columnContainer {
  height: 300px;
  margin-bottom: 40px;
}

.short {
  height: 220px;
}

.statsTitle {
  color: $color-grey;
  font-family: $font-family-primary;
  position: absolute;
  left: 0;
  bottom: -52px;
  width: 100%;
  margin-top: 0;
  font-size: 23px;
  line-height: 23px;
  text-align: center;
}

.categoryTitle {
  text-align: left;
  font-family: $font-family-secondary;
  font-size: 18px;
  letter-spacing: 0.43px;
  line-height: 26px;
}

.square {
  margin-right: 10px;
  height: 32px;
  min-width: 32px;
  border-radius: 12px;
  background-color: $color-blue-light;
}

.tooltipText {
  padding-top: 5px;
  font-family: $font-family-primary;
}

.instructions {
  font-family: $font-family-primary;
}

.instructionsContainer {
  position: absolute;
  top: 50px;
  right: -240px;
  max-width: 240px;
  opacity: 0;
  animation: fadeIn ease 0.6s;
  animation-fill-mode: both;
  animation-delay: 1s;
  will-change: opacity;
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
}

.bulb {
  margin-bottom: 13px;
  opacity: 0;
  animation: bounceIn ease 0.6s;
  animation-fill-mode: both;
  animation-delay: 1.1s;
  will-change: transform, opacity;
  @keyframes bounceIn {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    60% {
      transform: translateY(-10px) scale(1.05, 0.9);
    }
    80% {
      transform: translateY(5px);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.pointLeft {
  margin-top: 42px; //height of lightbulb
  padding-right: 6px;
  animation: sideBobbing 1s infinite alternate;
  will-change: transform;

  @keyframes sideBobbing {
    0% {
      transform: translateX(0);
    }
    95% {
      transform: translateX(-10px);
    }
    100% {
      transform: translateX(-10px);
    }
  }
}

@include media-breakpoint-down(sm) {
  .statsTitle {
    font-size: 15px;
  }

  .categoryTitle {
    font-size: 14px;
  }
}

@include media-breakpoint-down(md) {
  .container {
    padding-top: 20px;
  }
}

@include media-breakpoint-down(lg) {
  .instructionsContainer {
    position: relative;
    top: 0;
    right: 0;
    max-width: 270px;
    margin: 0 auto;
  }

  .pointLeft {
    margin-top: 0px;
    padding: 0;
    animation: sideBobbing 1s infinite alternate;
    will-change: transform;

    @keyframes sideBobbing {
      0% {
        transform: rotate(-90deg) translateX(10px);
      }
      95% {
        transform: rotate(-90deg) translateX(0);
      }
      100% {
        transform: rotate(-90deg) translateX(0);
      }
    }
  }
}
