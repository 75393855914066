@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.field {
  height: 50px;
  border-radius: 29px;
  border: 1px solid $color-grey-light;
  position: relative;
  cursor: text;
  caret-color: $color-pink;
  background: $color-white;
  padding-left: 20px;
}

.input {
  width: 100%;
  height: 51px;
  position: relative;
  padding: 0 10px;
  border: none;
  font-family: $font-family-secondary;
  font-size: 18px;
  line-height: 26px;
  background-color: transparent;
  color: $color-grey;
  outline: none;
}

.label {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 14px;
  right: 30px;
}

.error {
  color: $color-pink;
  font-size: 14px;
  text-align: center;
  margin-bottom: -10px;
  margin-top: 5px;
}

.title {
  position: absolute;
  top: -40px;
  left: -3px;
  width: 100%;
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
}

@include media-breakpoint-down (md) {
  .field {
    padding-left: 20px;
    margin: 0 13px;
  }

  .title {
    font-size: 15px;
    top: -30px;
    line-height: 23px;
  }
}
