@import '../../node_modules/rc-tooltip/assets/bootstrap.css';
@import '../../styles/functions';
@import '../../styles/variables';

.rc-tooltip-content {
  min-height: 34px;
  border-radius: 10px;
  background-color: $color-grey;
  box-shadow: 0 8px 8px 0 rgba(rgba(88, 88, 91, 1), 0.47);
}

.rc-tooltip-inner {
  text-align: center;
  font-size: 18px;
  font-family: $font-family-primary;
  background-color: $color-grey;
}

.rc-tooltip-arrow {
  border-top-color: $color-grey !important;
}

.rc-tooltip.rc-tooltip-placement-topLeft {
  .rc-tooltip-content,
  .rc-tooltip-content .rc-tooltip-inner {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }

  .rc-tooltip-arrow {
    display: none;
  }
}

.rc-tooltip.rc-tooltip-placement-top {
  opacity: .95;

  .rc-tooltip-content {
    background: $color-white;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2);
    max-width: 490px;
    padding: 30px;
    border-radius: 10px;
    text-align: center;

  .rc-tooltip-inner {
    box-shadow: none;
    color: $color-grey;
    background: $color-white;
    font-family: $font-family-secondary;
  }

    .rc-tooltip-arrow {
      border-top-color: $color-white !important;
    }
  }
}
