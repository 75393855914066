@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  background-color: $color-white;
  color: $color-black;
}

.headerText {
  color: $color-grey;
  font-family: $font-family-primary;
  font-size: 60px;
  font-weight: bold;
  line-height: 56px;
}

.headerWrapper {
  position: relative;
}

.iceland {
  position: relative;
}

.icelandShadow {
  position: absolute;
  bottom: -50px;
  left: 0;
  z-index: 1;
  margin-top: 53%;
}

.textStyle {
  color: $color-grey;
  font-family: $font-family-secondary;
  font-size: 18px;
  line-height: 26px;
}

.viewDetails {
  color: $color-orange;
  font-family: $font-family-primary;
  font-weight: 900;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  text-align: justify;
}

.viewDetailsLink {
  border: 0;
  color: $color-orange;
  border-bottom: 1px solid $color-orange;

  &:hover,
  &:focus {
    opacity: 0.8;
    color: $color-orange;
  }
}

.downArrow {
  cursor: pointer;
  padding: 10px;
  animation: bobbing 1s infinite alternate;

  @keyframes bobbing {
    0% {
      transform: translateY(0);
    }
    95% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(20px);
    }
  }
}

@include media-breakpoint-down(md) {
  .textStyle {
    text-align: center;
  }

  .headerText {
    font-size: 28px;
    line-height: 30px;
    text-align: center;
  }

  .iceland {
    svg {
      height: 100%;
      width: 85vw;
    }
  }

  .icelandShadow {
    img {
      width: 80vw;
    }
  }
}
