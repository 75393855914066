.styleguide {
  &__colorbox {
    display: block;
    width: 60px;
    height: 60px;
  }

  &--color-grey-bg {
    background-color: $body-color;
  }

  &--color-blue {
    background-color: $color-blue;
  }

  &--color-yellow {
    background-color: $color-yellow;
  }

  &--color-red {
    background-color: $color-red;
  }

  &--color-green {
    background-color: $color-green;
  }

  &--color-green-light {
    background-color: $color-green-light;
  }
}
