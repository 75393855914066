@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.headerText {
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}

.textStyle {
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.43px;
  word-break: break-word;
  padding-bottom: 60px;

  @include media-breakpoint-down(md) {
    font-size: 14px;
  }
}
