@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  background-color: $color-grey-medium;
  color: $color-black;
  box-shadow: inset 0 1px 20px 0 rgba($color-black, 0.06);
  height: auto;
  max-height: 70px;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  z-index: 10;
}

.containerOptionsButton {
  svg {
    color: $color-grey;
  }
}

.arrow {
  transition: transform 0.3s ease-in-out;
}

.open {
  max-height: 780px;
  height: 100%;
  transition: max-height 0.3s ease-in-out;
  padding-bottom: 10px;

  .arrow {
    transform: rotate(180deg);
  }

  @include media-breakpoint-down(md) {
    max-height: 1000px;
  }
}

.headerText {
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
}

.textStyle {
  text-transform: uppercase;
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  padding-top: 5px;
  min-width: 215px;
}

.moreOptionsButton {
  outline: none;
  border: none;
  text-align: center;
  justify-content: center;
  font-family: none;
}

.numberPicker {
  width: 165px;
  border: 1px solid $color-grey-light;
  border-radius: 29px;
  background-color: $color-white;
}

.pickerTitle {
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
}

.plusMinusButtons {
  font-size: 35px;
  font-weight: 900;
}
