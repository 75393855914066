.button {
  text-align: center;
  border: none;
  background-color: transparent;
  
  &:hover,
  &:focus {
    outline: none;
  }

  &__text {
    padding-top: 4px;
  }

  &--back {
    cursor: pointer;
    align-items: center;
    padding: 20px 30px;
    background-color: $color-pink;
    font-family: $font-family-secondary-bold;
    font-size: 15px;
    line-height: 18px;
    outline: 0;
    text-transform: uppercase;
    color: $color-white;
    border-radius: 5px;
    height: 50px;

    div {
      padding-top: 4px;
    }
  }

  &--navigate {
    height: 50px;
    cursor: pointer;
    align-items: center;
    padding: 20px 30px;
    font-family: $font-family-secondary-bold;
    font-size: 15px;
    line-height: 18px;
    outline: 0;
    text-transform: uppercase;
    border-radius: 5px;
    min-width: 240px;
    margin: 0 15px;

    div {
      padding-top: 4px;
    }
  }

  &--pink {
    background-color: $color-pink;
    color: $color-white;
  }

  &--white {
    color: $color-grey;
    border: 1px solid $color-grey;
  }

  &--calculate {
    align-items: center;
    text-transform: uppercase;
    width: 304px;
    height: 50px;
    border-radius: 5px;
    outline: 0;
    font-family: $font-family-secondary-bold;
    font-size: 15px;
    line-height: 18px;

    svg {
      margin-left: 20px;
    }
  }

  &--stats {
    text-transform: uppercase;
    height: 50px;
    width: 203px;
    border-radius: 5px;
    background-color: $color-blue-light;
    outline: 0;
    color: $color-white;
    font-family: $font-family-secondary-bold;
    font-size: 15px;
    line-height: 18px;
    border: none;

    svg {
      margin-left: 10px;
    }
  }
}

@include media-breakpoint-down (md) {
  .button {
    &--calculate {
      margin: 0 15px;
    }

    &--calculate,
    &--navigate {
      width: 100%;
    }

    &--stats {
      margin-top: 25px;
      width: 100%;
    }
  }
}
