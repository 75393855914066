.form-control,
.form-input {
  font-size: 18px;
  font-weight: 'normal';
  line-height: 34px;

  &:focus {
    outline-width: 2px;
  }
}

// select.form-control,
// select.form-input {
//   @extend .form-control__select;
// }

// select.form-control--noborder {
//   @extend .form-control--noborder;
// }

// input[type='radio'].form-control,
// input[type='radio'].form-input,
// input[type='radio'].form-radio {
//   @extend .form-control__radio;
// }

// input[type='checkbox'].form-control,
// input[type='checkbox'].form-input,
// input[type='checkbox'].form-checkbox {
//   @extend .form-control__check;
// }

// input[type='submit'].form-control,
// input[type='submit'].form-input {
//   @extend .form-control__submit;
// }

// textarea.form-control,
// textarea.form-input {
//   @extend .form-control__textarea;
// }
