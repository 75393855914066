@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.modal {
  position: fixed;
  width: 500px;
  top: 40%;
  left: calc(50% - 250px);
  background: $color-orange;
  padding: 30px;
  text-align: center;
  border-radius: 20px;
  color: $color-white;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
}

.icon {
  margin-bottom: 18px;
}

.headerText {
  font-family: $font-family-secondary-bold;
  font-weight: 500;
  margin-bottom: 7px;
}

.text {
  font-family: $font-family-secondary;
  margin: 0;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

@include media-breakpoint-down(xs) {
  .modal {
    left: 5px;
    right: 5px;
    max-width: none;
    width: auto;
  }
}
