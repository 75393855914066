@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.numberInputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: $font-family-secondary-bold;
  color: $color-grey;
  font-size: 18px;
  line-height: 23px;

  @include media-breakpoint-down('md') {
    padding: 0;
    text-align: center;
  }
}

.description {
  margin-top: -10px;
}

.buttonArea {
  padding: 0 26px;
  cursor: pointer;
}

.numberPicker {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 165px;
  height: 50px;
  border: 1px solid $color-grey-light;
  border-radius: 29px;
  background-color: $color-white;

  @include media-breakpoint-down('sm') {
    min-width: 145px;
  }
}

.pickerTitle {
  font-family: $font-family-secondary-bold;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
}

.plusMinusButtons {
  line-height: 0;
  margin-top: 17px;
  font-size: 35px;
  font-weight: 900;
}

.error {
  display: none;
  color: $color-pink;
  font-size: 14px;
  text-align: center;
  padding-top: 5px;
  min-height: 31px;
}
