.mobile-tooltip {
  display: none;
}

.statsEurope {
  width: 69px;
  min-height: 10px;
  border-radius: 20px 20px 0 0;
  background-color: $color-blue-light;
  border: 3px solid $color-white;
  border-bottom: none;
  transition: height 0.6s ease;

  &.EuResults {
    background-color: $color-blue-light;
  }
}

.statsIceland {
  min-height: 10px;
  width: 75px;
  border-radius: 20px 20px 0 0;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: $gray-500;
  border: 3px solid $color-white;
  border-bottom: none;
  transition: height 0.6s ease;
  transition-delay: 0.2s;

  &.IsResults {
    background-color: $gray-500;
    right: 33%;
  }
}

.statsIndividual {
  min-height: 10px;
  position: relative;
  width: 75px;
  border-radius: 20px 20px 0 0;
  background-color: $color-pink;
  border: 3px solid $color-white;
  border-bottom: none;
  transition: height 0.6s ease;
  transition-delay: 0.4s;

  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;

    .mobile-tooltip {
      display: inline;
      position: absolute;
      right: auto;
      left: auto;
      top: -40px;
      color: $color-grey;
      text-align: center;
      text-shadow: -1px 1px 0 $color-white-08;

      &__result {
        font-family: $font-family-secondary-bold;
        font-size: 18px;
        line-height: 17px;
        font-weight: $font-weight-bold;
      }

      &__unit {
        font-size: 14px;
      }
    }
  }
}

.grey-border {
  border-color: $color-grey-medium;
}

@include media-breakpoint-down(sm) {
  .statsEurope,
  .statsIndividual {
    width: 50px;
    border-radius: 10px 10px 0 0;
    margin-left: -22px;
  }

  .statsIceland {
    width: 50px;
    border-radius: 10px 10px 0 0;
    margin-left: -6px;
  }
}
