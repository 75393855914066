@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  background-color: $color-white;
  color: $color-grey;

  h2 {
    color: $color-grey;
  }
}

.headerText {
  font-family: $font-family-primary;
  font-weight: 500;
  font-size: 60px;
  line-height: 66px;
  text-align: center;
  padding-top: 93px;
}

.list {
  p {
    padding: 0;
    font-size: 18px;
    letter-spacing: .43px;
    line-height: 18px;
  }
}

.textStyle {
  color: $color-grey;
  font-family: $font-family-secondary;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: .43px;
  line-height: 26px;
  font-weight: 700;

  li {
    strong {
      font-family: $font-family-secondary-bold;
    }
  }

  p {
    strong {
      font-family: $font-family-secondary-bold;
      font-size: 18px;
      letter-spacing: .43px;
      line-height: 26px;
    }

    h3 {
      color: $color-grey;
    }
  }
}

.textStyleBold {
  font-family: $font-family-secondary-bold;
}

@include media-breakpoint-down (sm) {
  .headerText {
    font-size: 28px;
  }

  .socialIcons {
    svg {
      margin: 0 10px;
    }
  }
}
