@import '../../styles/variables';

.container {
  opacity: 0;
  transition: opacity .4s ease .3s;
}

.containerPanel {
  transform: translateX(100%);
  transition: transform $transition-bezier .3s, opacity .5s ease .3;
}

.transition {
  opacity: 1;
  transform: translateX(0);
}
