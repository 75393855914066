%base-headline-style {
  font-family: $font-family-sans-serif;
  font-weight: normal;
}

%list-spacing {
  + ul,
  + li {
    margin-top: 32px;
  }
}

.hdln {
  &--1 {
    @extend %base-headline-style;
    font-size: px-to-base-rem(96);
    line-height: px-to-base-rem(100);
    font-weight: 900;

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      font-size: px-to-base-rem(60);
      line-height: px-to-base-rem(66);
    }
  }

  &--2 {
    @extend %base-headline-style;
    font-size: px-to-base-rem(44);
    line-height: px-to-base-rem(50);
    font-weight: 800;

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      font-size: px-to-base-rem(32);
      line-height: px-to-base-rem(40);
    }
  }

  &--3 {
    @extend %base-headline-style;
    @extend %list-spacing;
    font-size: px-to-base-rem(30);
    font-weight: 500;
  }

  &--4 {
    @extend %base-headline-style;
    @extend %list-spacing;
    font-size: px-to-base-rem(21);
    line-height: px-to-base-rem(26);
    font-weight: 800;
  }

  &--5 {
    @extend %base-headline-style;
    @extend %list-spacing;
    font-size: px-to-base-rem(18);
    font-weight: 300;
  }

  &--6 {
    @extend %base-headline-style;
    @extend %list-spacing;
  }

  &--x-small {
    @extend %base-headline-style;
    font-family: $font-family-secondary;
    font-size: px-to-base-rem(18);
    line-height: px-to-base-rem(26);
  }

  &--small {
    @extend %base-headline-style;
    font-family: $font-family-secondary;
    font-size: px-to-base-rem(14);
    line-height: px-to-base-rem(16);
    font-weight: 400;
  }

  &--medium {
    font-family: $font-family-primary;
    font-size: px-to-base-rem(23);
    line-height: px-to-base-rem(23);
    font-weight: 400;
  }

  &--large {
    font-family: $font-family-primary;
    font-size: px-to-base-rem(23);
    line-height: px-to-base-rem(23);
    font-weight: 400;
  }

  &--x-large {
    font-family: $font-family-primary;
    font-size: px-to-base-rem(60);
    line-height: px-to-base-rem(66);
    font-weight: 500;
  }

  &--white {
    color: $color-white;
  }

  &--grey {
    color: $color-grey;
  }

  &--pink {
    color: $color-pink;
  }

  &--border {
    padding-top: 25px;
    border-top: 1px solid rgba($color-white, .5);
  }

  &--underline {
    text-decoration: underline;
    text-decoration-color: $color-yellow;
  }
}

.lead {
  font-family: $font-family-sans-serif;
  font-size: px-to-base-rem(44);
  font-weight: 800;
  line-height: px-to-base-rem(50);

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    font-size: px-to-base-rem(32);
    line-height: px-to-base-rem(40);
  }
}

.wp-lead {
  p {
    @extend .lead;
  }
}

a {
  transition: color .3s ease;

  &:hover {
    color: $color-white;
  }
}

p {
  font-family: $font-family-secondary;
  font-size: $font-size-base;
  font-weight: 400;
  line-height: px-to-base-rem(26);

  + p,
  + h2,
  + h3,
  + h4,
  + h5,
  + h6,
  + ul,
  + li {
    margin-top: 30px;
  }

  a {
    -webkit-box-shadow: 0px 1px 0px 0px rgba(88, 88, 91, 1);
    -moz-box-shadow: 0px 1px 0px 0px rgba(88, 88, 91, 1);
    box-shadow: 0px 1px 0px 0px rgba(88, 88, 91, 1);

    &:hover {
      -webkit-box-shadow: 0px 1px 0px 0px rgba(232, 62, 140, 1);
      -moz-box-shadow: 0px 1px 0px 0px rgba(232, 62, 140, 1);
      box-shadow: 0px 1px 0px 0px rgba(232, 62, 140, 1);
      color: $color-pink;
    }

    @extend .link--standard;
  }

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    font-size: px-to-base-rem(19);
    line-height: px-to-base-rem(26);
  }
}

ul {
  list-style: none;

  li::before {
    content: '';
    position: absolute;
    left: 0;
    top: 14px;
    width: 14px;
    height: 2px;
    background-color: $color-grey;
  }
}

ul, ol {
  padding: 0;
  font-family: $font-family-secondary;
  font-size: $font-size-base;
  font-weight: 300;
  line-height: px-to-base-rem(30);

  a {
    @extend .link--standard;
  }

  li {
    padding-left: 40px;
    padding-bottom: 25px;
    position: relative;

    ol,
    ul {
      margin-top: 25px;
    }
  }
}

ol {
  padding-left: 26px;
  font-family: $font-family-secondary;
  font-size: $font-size-base;
  font-weight: 300;
  line-height: px-to-base-rem(30);

  a {
    @extend .link--standard;
  }

  li {
    padding-left: 14px;
    padding-bottom: 25px;

    ol,
    ul {
      margin-top: 25px;
    }
  }
}

blockquote {
  font-size: px-to-base-rem(18);
  line-height: 1.5;
  font-weight: 600;
  padding: 25px 120px;
  position: relative;
  margin-top: 40px;
  margin-bottom: 40px;

  &::before {
    content: '„';
    font-family: $font-family-sans-serif;
    font-size: px-to-base-rem(120);
    font-weight: 100;
    line-height: .88;
    position: absolute;
    left: 30px;
    bottom: 0;
    color: $color-white;
  }

  &::after {
    content: '“';
    font-family: $font-family-sans-serif;
    font-size: px-to-base-rem(120);
    font-weight: 100;
    line-height: .88;
    position: absolute;
    right: 30px;
    top: 0;
    color: $color-black;
  }

  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    padding: 25px 40px;

    &::before {
      left: 10px;
      font-size: px-to-base-rem(80);
    }

    &::after {
      right: 10px;
      font-size: px-to-base-rem(80);
    }
  }
}

h1 { @extend .hdln--1; }
h2 { @extend .hdln--2; }
h3 { @extend .hdln--3; }
h4 { @extend .hdln--4; }
h5 { @extend .hdln--5; }
h6 { @extend .hdln--6; }
