@import "~video-react/styles/scss/video-react"; // or import scss

.video-react .video-react-big-play-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 0;
  transform: translateY(-50%);
  margin-top: 0;

  &::before {
    font-size: 55px;
    top: 30px;
  }
}

.video-react .video-react-big-play-button.video-react-big-play-button-center {
  margin-top: 0;
}
