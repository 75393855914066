@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.socialIcons {
  text-align: center;

  img {
    cursor: pointer;
    display: inline-block;
    margin: 0 20px;
  }
}

.socialHeading {
  font-family: $font-family-secondary-bold;
  margin-bottom: 5px;
}

.socialText {
  margin-bottom: 5px;
}

@include media-breakpoint-down('sm') {
  .socialIcons {
    img {
      margin: 0 10px;
    }
  }
}
