@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  background-color: $color-grey-medium;
  color: $color-black;
  box-shadow: inset 0 1px 20px 0 rgba($color-black, .06);
}

.headerText {
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
}
