@import '../../../node_modules/react-tabs/style/react-tabs';

.react-tabs {
  &__tab-list {
    border: none;
    margin-bottom: 0;
    position: relative;
    z-index: 4;
    background: $color-white;
  }

  &__tab {
    margin: 0 30px;
    padding: 0;
    font-family: $font-family-secondary;
    font-weight: 600;
    text-align: center;
    color: $color-grey;
    outline: 0;
    height: 30px;
    background-color: $color-white;

    &--selected {
      outline: none;
      background: transparent;
      color: $color-grey;
      border: 0;
      border-bottom: 3px solid $color-pink;
      font-family: $font-family-secondary-bold;
    }

    &:focus::after {
      content: "";
      background: transparent;
      outline: 0;
      font-family: $font-family-secondary-bold;
      font-weight: 200;
    }
  }

  .text-center {
    height: 30px;
  }

  &__tab::before {
    background-color: transparent;
  }

  &__tab-panel {
    z-index: 5;
    outline: none;
  }
}
