@import "../../styles/functions";
@import "../../styles/variables";
@import '../../../node_modules/bootstrap/scss/mixins';

.stepContainer {
  text-align: center;
  padding-bottom: 30px;
}

.inactiveTitle {
  font-size: 23px;
  font-family: $font-family-primary;
  font-weight: bold;
  margin: 0;
  width: 100%;
  color: $color-grey-light;

  &.activeTitle {
    color: $color-grey;
  }

  @include media-breakpoint-down (md) {
    font-size: 20px;
  }

  @include media-breakpoint-down (sm) {
    font-size: 14px;
  }
}

.outerEmpty {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 8px solid transparent;

  &.outerCircle {
    border: 8px solid $color-green;
  }
}

.innerCircle {
  font-family: $font-family-primary;
  text-align: center;
  margin: -5px;
  padding: 7px;
  height: 34px;
  width: 34px;
  border: 2px solid $color-grey-light;
  border-radius: 50%;
  background-color: $color-white;
  color: $color-grey-light;

  &.activeCircle {
    border: 2px solid;
    background-color: $color-green;
    color: $color-white;
  }
}
