@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.container {
  background-color: $color-white;

  p {
    line-height: 0;
    margin-top: 17px;
    position: relative;
  }
}

.divider {
  box-sizing: border-box;
  height: 32px;
  width: 1px;
  border: 1px solid $color-grey;
  opacity: .7;
  margin-right: 20px;

  @include media-breakpoint-down (sm) {
    display: none;
  }
}

.textStyle {
  color: $color-grey;
  font-family: $font-family-secondary;
  opacity: .8;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  margin-right: 20px;

  @include media-breakpoint-down (sm) {
    padding-top: 5px;
  }
}

.underline {
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -10px;
    height: 1px;
    background-color: $color-grey;
  }
}

.or {
  width: 79px;
  height: 25px;
  background-image: url('../../assets/images/OR-islenska-litur.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include media-breakpoint-down (sm) {
    margin-right: 10px;
  }
}

.efla {
  height: 25px;
  margin-left: 20px;
  margin-right: 20px;

  @include media-breakpoint-down (sm) {
    margin-left: 10px;
  }
}
