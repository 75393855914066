@include custom-font('../assets/fonts/helvetica-now/display/HelveticaNowDisplay-Black', HelveticaNowDisplay, 900, normal);
@include custom-font('../assets/fonts/helvetica-now/display/HelveticaNowDisplay-BlackItalic', HelveticaNowDisplay, 900, italic);

@include custom-font('../assets/fonts/helvetica-now/display/HelveticaNowDisplay-ExtraBold', HelveticaNowDisplay, 800, normal);
@include custom-font('../assets/fonts/helvetica-now/display/HelveticaNowDisplay-ExtraBoldItalic', HelveticaNowDisplay, 800, italic);

@include custom-font('../assets/fonts/helvetica-now/display/HelveticaNowDisplay-ExtraBlack', HelveticaNowDisplay, 1000, normal);
@include custom-font('../assets/fonts/helvetica-now/display/HelveticaNowDisplay-ExtraBlackItalic', HelveticaNowDisplay, 1000, italic);

@include custom-font('../assets/fonts/helvetica-now/text/HelveticaNowText-Regular', HelveticaNow, 400, normal);
@include custom-font('../assets/fonts/helvetica-now/text/HelveticaNowText-Bold', HelveticaNow, bold, normal);
@include custom-font('../assets/fonts/colfax/Colfax-Regular', ColfaxRegular, 900, normal);
@include custom-font('../assets/fonts/helvetica-now/text/HelveticaNowText-Bold', HelveticaNow, bold, normal);
@include custom-font('../assets/fonts/colfax/Colfax-Medium', ColfaxMedium, 500, normal);
@include custom-font('../assets/fonts/colfax/Colfax-Bold', ColfaxBold, 700, normal);
@include custom-font('../assets/fonts/baloo/BalooTammudu-Regular', BalooRegular, 900, normal);

.gatsby-image-outer-wrapper {
  height: 100%;
}

html {
  min-height: 100%;
  position: relative;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: normal;
  background-color: $color-white;
  // color: $color-white;
  min-height: 100%;
  position: relative;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  @media (max-width: map-get($grid-breakpoints, 'sm')) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
