@import '../../styles/variables';

.header {
  z-index: 9;
  top: 0;
  width: 100%;
  transition: width .3s ease;
  padding-bottom: 20px;
  pointer-events: none;

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    min-width: 100%;
  }
}

.maxWidth {
  min-height: stretch;
  margin-left: auto;
  margin-right: auto;
  background-color: $color-white;
}
