@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.headerText {
  color: $color-grey;
  font-family: $font-family-secondary-bold;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}

.active {
  max-height: 500px;
  transition: max-height .5s ease-in-out;

  @include media-breakpoint-down (md) {
    max-height: 1000px;
  }
}

.textStyle {
  color: $color-grey;
  font-family: $font-family-secondary;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.43px;
  line-height: 26px;
  text-align: center;
  font-weight: 700;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.disabledInput {
  transition: max-height .5s ease-in-out;
  max-height: 0;
}
