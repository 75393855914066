
.anim {
  > *:nth-child(2) .anim__line {
    transition-delay: .6s;
  }

  > *:nth-child(3) .anim__line {
    transition-delay: .8s;
  }

  &__line {
    display: block;
    transform: translateY(110%);
    margin-bottom: 6px;
  }

  &--show {
    .anim__line {
      transform: translateY(0%);
      transition: opacity .3s ease .4s, transform .3s ease .4s;
    }
  }
}
