@import "../../styles/variables";

.container .RSPBprogressBar .RSPBstep {
  display: block;
  top:0;
  transform: translateX(-50%) translateY(-50%);
  width: 25%;
}

.RSPBprogression {
  position: absolute;
  transition: width .3s ease;
  border-radius: 10px;
  background: $color-green;
  z-index: -1;
  border: 3px;
  margin-top: -1px;

  &::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #6ca234;
    display: block;
    top:0;
    left: 100%;
    transform: translate(-4px, -3px);
  }
}
