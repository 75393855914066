@import '../../node_modules/rc-slider/assets/index.css';

.rc-slider-handle {
  margin-top: -15px;
  height: 32px;
  width: 32px;
  border: 2px solid $color-pink;
  background-color: $color-white;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/lines.png');

  &:hover,
  &:focus,
  &:active {
    border-color: $color-pink;
    box-shadow: 0 0 5px $color-pink;
  }
}

.rc-slider-mark {
  @media (max-width: map-get($grid-breakpoints, 'md')) {
    left: 10px;
  }
}

.rc-slider-mark-text,
.rc-slider-dot-active {
  width: 200px;
  bottom: 40px;
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 0.43px;
  line-height: 24px;
  text-align: center;
  opacity: 1;
  font-family: $font-family-secondary;
  color: $color-grey;

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    width: 70px;
    font-size: 14px;
  }
}

.rc-slider-rail {
  background-color: $color-grey-dark;
  height: 3px;
  right: -2px;
}

.rc-slider-track {
  background-color: $color-pink;
  height: 4px;
}

.rc-slider-dot {
  border: none;
  background-color: transparent;
}

.rc-slider-dot-active {
  border-color: $color-pink;
}
