@import '../../styles/functions';
@import '../../styles/variables';
@import '../../../node_modules/bootstrap/scss/mixins';

.header {
  position: relative;

  button {
    border: none;
    outline: none;
    background: transparent;
  }
}

.icon {
  position: absolute;
  right: 20px;
  bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@include media-breakpoint-down (md) {
  .icon {
    right: 0;
  }

  .text {
    line-height: 1.83rem;
  }

  .logo {
    svg {
      width: 53vw;
    }
  }
}
